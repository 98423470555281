import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';

import type { ImageSizes } from '../../helpers/images';
import { VimeoPlugin } from '../../types/Vimeo';
import type { NFTProductType } from '../../types/web3/product';
import { getFirstStoryblokParagraph } from '../../utils/renderStoryblokRichText';
import BannerShell from './BannerShell';
import type { BannerAnalytics, BannerLayout, StoryblokBannerV3 } from './types';

type Props = {
  banner: StoryblokBannerV3<NFTProductType>;
  layout: BannerLayout;
  className?: string;
  imageSizes: ImageSizes;
  analytics: BannerAnalytics;
  productArtistNames: { [key: string]: string | null };
};

const BannerNFT: React.FC<Props> = ({
  analytics,
  banner,
  className,
  imageSizes,
  layout,
  productArtistNames = {},
}) => {
  return (
    <BannerShell
      analytics={analytics}
      category={useBannerCategory(banner)}
      className={className}
      description={useBannerDescription(banner)}
      headline={useBannerHeadline(banner, productArtistNames)}
      href={useBannerHref(banner)}
      images={useBannerImages(banner, layout)}
      imageSizes={imageSizes}
      labels={useBannerLabels(banner)}
      layout={layout}
      video={useBannerVideo(banner)}
    />
  );
};

export default BannerNFT;

export const useBannerHref = (
  banner: StoryblokBannerV3<NFTProductType>,
): string => {
  return `/${banner.link.story.full_slug}`;
};

export const useBannerVideo = (banner: StoryblokBannerV3<NFTProductType>) => {
  return useMemo(() => {
    if (banner.overrideVideo?.vimeo_oembed) {
      return banner.overrideVideo;
    }

    if (banner.overrideImages && banner.overrideImages?.length > 0) {
      return undefined;
    }

    const vimeo = banner.link.story.content.images.filter(
      (p): p is ProductVideoComponent => p.component === 'Video',
    )[0]?.vimeo;
    if (vimeo) {
      return vimeo as unknown as VimeoPlugin;
    }
    return undefined;
  }, [banner]);
};

export const useBannerImages = (
  banner: StoryblokBannerV3<NFTProductType>,
  layout: BannerLayout,
): StoryblokImage[] => {
  return useMemo(() => {
    if (banner.overrideImages && banner.overrideImages.length > 0) {
      return banner.overrideImages.slice(0, 3);
    }

    const images = banner.link.story.content.images
      .filter((p): p is ProductImageComponent => p.component === 'image')
      .map((p) => p.image);

    return layout === 'HERO' ? images.slice(0, 3) : images.slice(0, 1);
  }, [banner]);
};

export const useBannerCategory = (
  banner: StoryblokBannerV3<NFTProductType>,
): string => {
  const { t } = useTranslation('banner');
  return banner.overrideCategory || t('v3.nft.title');
};

export const useBannerHeadline = (
  banner: StoryblokBannerV3<NFTProductType>,
  productArtistNames: { [key: string]: string | null },
): string => {
  return (
    banner.overrideHeadline ||
    productArtistNames[banner.link.story.content.title] ||
    banner.link.story.content.seo.title
  );
};

export const useBannerDescription = (
  banner: StoryblokBannerV3<NFTProductType>,
): StoryblokTextAreaType => {
  if (banner.overrideDescription) return banner.overrideDescription;

  return getFirstStoryblokParagraph(banner.link.story.content.description);
};

// TODO: fill in for NFTs
export const useBannerLabels = (_: StoryblokBannerV3<NFTProductType>) => {
  return [];
};

import React from 'react';
import type { WebSite } from 'schema-dts';

import { LdJson } from '../../scripts/LdJson';
import { validString } from '../../utils/schema';

export const WebsiteSchema = () => {
  const website: WebSite = {
    '@type': 'WebSite',
    'name': 'Avant Arte',
    'alternateName': 'Avant Arte',
    ...validString('url', process.env.NEXT_PUBLIC_HOSTNAME),
  };

  return <LdJson schema={website} />;
};

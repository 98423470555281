import classNames from 'classnames';

import type { ImageSizes } from '../../../helpers/images';
import { Image } from '../../Image';
import { BannerLabel } from '../types';
import BannerInfo from './BannerInfo';

type Props = {
  layout: 'SPOTLIGHT' | 'ARTIST';
  imageSizes: ImageSizes;
  labels: BannerLabel[];
  category: string;
  headline: string;
  description: string;
  image: StoryblokImage;
};

const BannerTile: React.FC<Props> = ({
  category,
  description,
  headline,
  image,
  imageSizes,
  labels,
  layout,
}) => {
  return (
    <div className="group relative grid grid-cols-2 gap-5">
      <div
        className={classNames(
          'overflow-hidden rounded-sm',
          layout === 'ARTIST' ? 'col-span-2 lg:col-span-1' : 'col-span-2',
        )}
      >
        <Image
          alt={image.alt}
          focus={image.focus}
          hoverEffect
          size={imageSizes}
          src={image.filename}
        />
      </div>
      <div
        className={classNames('relative', {
          'col-span-2 lg:col-span-1': layout === 'ARTIST',
          'col-span-2': layout === 'SPOTLIGHT',
        })}
      >
        <BannerInfo
          category={category}
          description={description}
          headline={headline}
          labels={labels}
        />
      </div>
    </div>
  );
};

export default BannerTile;

import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { ImageSizes } from '../../helpers/images';
import { delay } from '../../utils/promises';
import { Image } from '../Image';

type Props = {
  eagerLoading?: boolean;
  images?: {
    alt: string;
    focus?: string;
    src: string;
  }[];
  size: ImageSizes;
};

const Slideshow: React.FC<Props> = ({
  eagerLoading = false,
  images = [],
  size,
}) => {
  const slideshow = useSlideshow({ numImages: images.length, duration: 2000 });

  return (
    <div className="relative flex h-full">
      {images.map((image, index) => {
        const isPrevious = index === slideshow.previousIndex;
        const isCurrent = index === slideshow.currentIndex;
        const isNext = index === slideshow.nextIndex;

        return (
          <div
            key={image.src}
            className={classNames('absolute left-0 top-0 h-full w-full', {
              'z-20 opacity-0': !isCurrent && !isNext && !isPrevious,
              'z-30 !opacity-100': isNext,
              'z-40 !opacity-100': isCurrent,
              'z-50 opacity-0 transition-opacity duration-700': isPrevious,
            })}
          >
            <Image
              alt={image.alt}
              className="h-full"
              eagerLoading={eagerLoading && index === 0}
              focus={image.focus}
              onLoad={() => {
                if (index === 0) {
                  slideshow.start();
                }
              }}
              size={size}
              src={image.src}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Slideshow;

const useSlideshow = ({
  defer = 4000,
  duration = 300,
  numImages = 0,
}: {
  numImages: number;
  defer?: number;
  duration: number;
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [started, setStarted] = useState(false);

  const start = useCallback(() => {
    delay(defer).then(() => {
      setStarted(true);
    });
  }, []);

  useEffect(() => {
    if (!started) return;

    const t = setInterval(() => {
      setCurrentIndex((i) => (i === numImages - 1 ? 0 : i + 1));
    }, duration);

    return () => clearInterval(t);
  }, [started, numImages]);

  return {
    start,
    currentIndex,
    nextIndex: currentIndex === numImages - 1 ? 0 : currentIndex + 1,
    previousIndex: currentIndex === 0 ? numImages - 1 : currentIndex - 1,
  };
};

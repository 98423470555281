import type { GetStaticPropsContext } from 'next';
import { useEffect } from 'react';
import { StoryData } from 'storyblok-js-client';

import { ssgClient } from '../apollo/api/avantArteClient';
import type { StoryblokBannerV3 } from '../components/BannerV3/types';
import Layout from '../components/Layout';
import HomeV2 from '../components/PageTypes/HomeV2';
import { OrganizationSchema, WebsiteSchema } from '../components/Schema';
import { HOME_PAGE_TYPE, ONE_HOUR_IN_SECONDS } from '../constants';
import { imageOgStaticLoader } from '../helpers/og-image';
import { MediumFilter } from '../types/Filters';
import {
  GeneratedGetDrawByProductIDsQuery,
  GeneratedGetDrawByProductIDsQueryVariables,
  GetDrawByProductIDsDocument,
  queryReviews,
} from '../types/generated';
import {
  ProductStoryblok,
  ProductV2Storyblok,
} from '../types/generated-storyblok';
import type { HomeV2Data, HomeV2PageType } from '../types/Home';
import { StoryblokVideo } from '../types/Vimeo';
import { TrackPageView } from '../utils/analytics';
import { uniqOn } from '../utils/arrays';
import { filterProducts, mapShopifyProductsToProducts } from '../utils/product';
import { fetchAllProducts } from '../utils/shopify/fetchAllProducts';
import Storyblok, { getStoryblokPage, useStoryblok } from '../utils/storyblok';
import { normaliseString } from '../utils/strings';
import { pageTranslations } from '../utils/translations';

type HomeV2Props = {
  story: StoryData<HomeV2PageType>;
  data: HomeV2Data;
  preview: boolean;
  locale?: string;
  locales?: string[];
  ogImageUrl: string;
};

const Page = ({
  data,
  locale,
  locales,
  ogImageUrl,
  preview,
  story: storyData,
}: HomeV2Props) => {
  useEffect(() => {
    TrackPageView({ page: HOME_PAGE_TYPE });
  }, []);

  const story = useStoryblok(storyData, preview, locale!);

  return (
    <>
      <Layout
        description={story.content.seo?.description}
        locale={locale}
        locales={locales}
        ogImageUrl={ogImageUrl}
        title={story.content.seo?.title}
      >
        <HomeV2 blok={story.content} data={data} />
      </Layout>
      <OrganizationSchema />
      <WebsiteSchema />
    </>
  );
};

export default Page;

export const getStaticProps = async (ctx: GetStaticPropsContext) => {
  const { locale, locales, preview = false } = ctx;

  const page = await getStoryblokPage('cdn/stories/home-v2', {
    locale,
    preview,
    resolve_links: 'story',
    resolve_relations: [
      'bannerHiglights.bannerV3.link',
      'bannerArtists.bannerV3.link',
      'reviews.review.product',
    ],
  });

  if (page === null) {
    throw new Error('No homepage is defined in Storyblok');
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const story = page.data?.story as StoryData<HomeV2PageType>;

  const ogImageUrl = await imageOgStaticLoader({
    overlineKey: { key: 'og:global.overline' },
    titleKey: { key: 'og:home.title' },
    imageSrc:
      'https://a.storyblok.com/f/119968/4000x2667/846735f337/parra-atelier-2.jpg',
    imageWidth: 1200,
    imageHeight: 630,
    overlay: true,
    locale,
  });

  const reviews = await queryReviews(ssgClient(ctx), {});
  const products = await getAllProducts();
  const studioVisits = await getStudioVisits(products);
  const productCountsByMedium = getProductCountsByMedium(products);
  const banners = [
    ...story.content.bannerArtists,
    ...story.content.bannerHero,
    ...story.content.bannerHighlights,
  ];

  const productArtistNames = getProductArtistNames(products, banners);
  const artistCollaborationStatuses = getArtistCollaborationStatuses(
    products,
    banners,
  );

  const bannerProductsIds = banners
    .filter(
      ({ link }) =>
        link.story?.content.component === 'product' ||
        link.story?.content.component === 'product_v2',
    )
    .map(({ link }) =>
      link.story?.content.component === 'product_v2'
        ? (link.story?.content as unknown as ProductV2Storyblok).shopifyId
        : (link.story?.content as unknown as ProductStoryblok).id[0].id,
    ) as string[];

  const draws = bannerProductsIds.length
    ? await ssgClient(ctx)
        .query<
          GeneratedGetDrawByProductIDsQuery,
          GeneratedGetDrawByProductIDsQueryVariables
        >({
          errorPolicy: 'all',
          query: GetDrawByProductIDsDocument,
          variables: {
            productIDs: bannerProductsIds,
          },
        })
        .then(({ data }) => data.getDrawByProductIDs)
    : [];

  return {
    revalidate: ONE_HOUR_IN_SECONDS,
    props: {
      ...(await pageTranslations(locale!, [
        'homev2',
        'artists',
        'reviews',
        'og',
      ])),
      story,
      data: {
        artistCollaborationStatuses,
        studioVisits,
        productArtistNames,
        productCountsByMedium,
        reviews: (reviews?.data?.reviews || []).slice(0, 8),
        draws,
      },
      locale,
      locales,
      preview,
      ogImageUrl,
    },
  };
};

const getAllProducts = async (): Promise<Product[]> => {
  return mapShopifyProductsToProducts(
    (await fetchAllProducts('tag:Listing page')).map((p) => p.node),
  );
};

const getProductCountsByMedium = (
  products: Product[],
): HomeV2Data['productCountsByMedium'] => {
  return {
    collectibles: countProducts(productsByMedium(products, 'Collectibles')),
    prints: countProducts(productsByMedium(products, 'Print editions')),
    studioWorks: countProducts(productsByMedium(products, 'Studio works')),
    sculptures: countProducts(productsByMedium(products, 'Sculpture editions')),
    nfts: countProducts(productsByMedium(products, 'NFTs')),
    all: countProducts(products),
  };
};

const getStudioVisits = async (products: Product[]) => {
  const stories = await Storyblok.getStories({
    per_page: 100,
    by_slugs: 'artists/*',
    sort_by: 'first_published_at:desc',
    filter_query: {
      exclusive: { is: 'not_empty_array' },
    },
  });

  const videos = stories.data.stories.reduce(
    (acc: HomeV2Data['studioVisits'], story) => {
      const artist = story as unknown as ArtistPageContent;

      const items = (artist.content.exclusive || []).filter(
        (item) => item.component === 'Video' && item.vimeo?.vimeo_oembed?.url,
      ) as StoryblokVideo[];

      if (items === undefined || items.length === 0) return acc;

      const artistProducts = products.filter(
        (product) =>
          normaliseString(product.artist) ===
          normaliseString(artist.content.name),
      );

      const videosToAdd = items.map((video) => ({
        vimeo: video.vimeo,
        coverImage: video.coverImage,
        showCopyright: video.showCopyright || false,
        artist,
        artistMetadata: {
          totalWorks: artistProducts.length,
          hideCollaborations: false,
        },
      }));

      return [...acc, ...videosToAdd];
    },
    [],
  );

  return videos
    .sort((a, b) => {
      const aDate = a.vimeo.vimeo_oembed.response.upload_date;
      const bDate = b.vimeo.vimeo_oembed.response.upload_date;
      return bDate.localeCompare(aDate);
    })
    .slice(0, 6);
};

const getProductArtistNames = (
  products: Product[],
  banners: StoryblokBannerV3[],
): HomeV2Data['productArtistNames'] => {
  const bannerProducts = banners
    .filter((b) => b.link?.story?.content.component === 'product')
    .map((b) => b.link?.story?.content as ProductType);

  const map: Record<string, string | null> = {};

  bannerProducts.forEach((bannerProduct) => {
    const shopifyProduct = products.find(
      (p) => normaliseString(p.title) === normaliseString(bannerProduct.title),
    );

    map[bannerProduct.title] = shopifyProduct?.artist || null;
  });

  return map;
};

const getArtistCollaborationStatuses = (
  products: Product[],
  banners: StoryblokBannerV3[],
): HomeV2Data['artistCollaborationStatuses'] => {
  const bannerArtists = banners
    .filter((b) => b.link?.story?.content.component === 'artist')
    .map((b) => b.link?.story?.content as ArtistPageType);

  const artists = uniqOn(bannerArtists, (a) => a._uid);

  return artists.map((artist) => {
    const works = products.filter(
      (product) =>
        normaliseString(product.artist) === normaliseString(artist.name),
    );

    const hasUpcomingRelease = works.some(
      (w) => w.availability === 'Coming soon',
    );

    const hasPlannedRelease =
      artist.plannedReleases !== null && artist.plannedReleases.length > 0;

    return {
      name: normaliseString(artist.name),
      numberOfCollaborations: works.length,
      hasUpcomingCollaboration: hasUpcomingRelease || hasPlannedRelease,
    };
  });
};

const productsByMedium = (products: Product[], medium: MediumFilter) => {
  return filterProducts(products, { mediums: [medium] });
};

const countProducts = (products: Product[]) => {
  return products.reduce((total, product) => {
    const productCount =
      product.productType === 'Collection' ? Number(product.editionSize) : 1;

    return total + productCount;
  }, 0);
};

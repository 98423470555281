import React from 'react';

import type { ImageSizes } from '../../helpers/images';
import { Franchises } from '../../types/Franchises';
import { GeneratedGetDrawByProductIDsQuery } from '../../types/generated';
import { ReleasePage } from '../../types/ReleaseContentType';
import type { NFTProductType } from '../../types/web3/product';
import BannerArtist from './BannerArtist';
import { BannerEvent } from './BannerEvent';
import { BannerInsights } from './BannerInsight';
import BannerLink from './BannerLink';
import BannerNFT from './BannerNFT';
import BannerProduct from './BannerProduct';
import { BannerRelease } from './BannerRelease';
import type {
  ArtistCollaborationStatus,
  BannerAnalytics,
  BannerLayout,
  StoryblokBannerV3,
} from './types';

type Props = {
  banner: StoryblokBannerV3;
  layout: BannerLayout;
  className?: string;
  imageSizes: ImageSizes;
  analytics: BannerAnalytics;
  artistCollaborationStatuses: ArtistCollaborationStatus[];
  productArtistNames: { [key: string]: string | null };
  draws: GeneratedGetDrawByProductIDsQuery['getDrawByProductIDs'];
};

const BannerContainer: React.FC<Props> = ({ banner, ...props }) => {
  const component = banner.link.story?.content?.component;

  if (component === 'events') {
    return (
      <BannerEvent
        banner={banner as StoryblokBannerV3<EventContentType>}
        {...props}
      />
    );
  }

  if (component === 'artist') {
    return (
      <BannerArtist
        banner={banner as StoryblokBannerV3<ArtistPageType>}
        {...props}
      />
    );
  }

  if (component === 'releasePage') {
    return (
      <BannerRelease
        banner={banner as StoryblokBannerV3<ReleasePage['content']>}
        {...props}
      />
    );
  }

  if (component === 'product' || component === 'product_v2') {
    return (
      <BannerProduct
        banner={banner as StoryblokBannerV3<ProductType>}
        {...props}
      />
    );
  }

  if (component === 'nft-product') {
    return (
      <BannerNFT
        banner={banner as StoryblokBannerV3<NFTProductType>}
        {...props}
      />
    );
  }

  if (component === 'franchise') {
    return (
      <BannerInsights
        banner={banner as StoryblokBannerV3<Franchises['content']>}
        {...props}
      />
    );
  }

  return <BannerLink banner={banner} {...props} />;
};

export default BannerContainer;

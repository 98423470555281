import { useDrawStage } from './useDrawStage';

// After moving all api calls to generated queries,
// this helper functions needs to go away
export const useIsPreOrder = (draw?: {
  closeAt: string;
  preOrderCloseAt?: string | null;
}) => {
  const stage = useDrawStage(draw);
  return stage === 'DRAW_PRE_ORDER_OPEN' || stage === 'FCFS_PRE_ORDER_OPEN';
};

import classNames from 'classnames';
import { useMemo } from 'react';

import { ImageSizes } from '../../../helpers/images';
import { VimeoPlugin } from '../../../types/Vimeo';
import { AspectRatio } from '../../AspectRatio/AspectRatio';
import BackgroundVideo from '../../BackgroundVideo';
import { Image } from '../../Image';
import Slideshow from '../../Slideshow';
import { BannerLabel } from '../types';
import BannerInfo from './BannerInfo';

// Always show the first image, show two on tablet, three on desktop
const IMAGE_CLASSES_BY_INDEX = ['block', 'hidden md:block', 'hidden lg:block'];

// Size images based on how many to show (e.g. desktop can be up to three)
const IMAGE_CLASSES_BY_NUM_TO_DISPLAY = [
  'col-span-12', // if there is one image
  'col-span-12 md:col-span-6', // if there are two images
  'col-span-12 md:col-span-6 lg:col-span-4', // if there are three images
];

type Props = {
  labels: BannerLabel[];
  category: string;
  headline: string;
  description: string;
  images: StoryblokImage[];
  imageSizes: ImageSizes;
  video?: VimeoPlugin;
  videoCover?: StoryblokImage;
};

const Banner: React.FC<Props> = ({
  category,
  description,
  headline,
  imageSizes,
  images,
  labels,
  video,
  videoCover,
}) => {
  const imgSizes = useMemo(() => {
    const maxImagesMd = Math.min(images.length, 2);
    const maxImagesLg = Math.min(images.length, 3);
    return {
      sm: imageSizes.sm,
      ...(imageSizes.md && {
        md: { ratio: imageSizes.md.ratio, span: 12 / maxImagesMd },
      }),
      ...(imageSizes.lg && {
        lg: { ratio: imageSizes?.lg.ratio, span: 12 / maxImagesLg },
      }),
    } as ImageSizes;
  }, [imageSizes, images.length]);

  const info = (
    <BannerInfo
      category={category}
      description={description}
      headline={headline}
      labels={labels}
    />
  );

  return (
    <div className="grid-section group relative">
      <div className="col-span-12">
        <AspectRatio
          className="grid overflow-hidden rounded-sm bg-white"
          lg="9/4"
          md="3/2"
          sm="3/4"
        >
          <div className="absolute inset-0 block h-full w-full md:hidden">
            <Slideshow
              eagerLoading
              images={images.map((image) => ({
                alt: image.alt,
                focus: image.focus,
                src: image.filename,
              }))}
              size={imgSizes}
            />
          </div>

          <div className="group-hero-banner absolute inset-0 hidden scale-100 duration-300 ease-out group-hover:scale-105 md:grid md:grid-cols-12">
            {video ? (
              <BackgroundVideo cover={videoCover} vimeo={video} />
            ) : (
              images.map((image, index) => (
                <div
                  key={image.id}
                  className={classNames(
                    IMAGE_CLASSES_BY_NUM_TO_DISPLAY[images.length - 1],
                    IMAGE_CLASSES_BY_INDEX[index],
                  )}
                >
                  <Image
                    alt={image.alt}
                    className="h-full"
                    eagerLoading
                    focus={image.focus}
                    size={imgSizes}
                    src={image.filename}
                  />
                </div>
              ))
            )}
          </div>
        </AspectRatio>
      </div>
      <div className="relative col-span-12 md:hidden">{info}</div>
      <div className="absolute bottom-0 right-0 hidden w-full justify-end p-6 md:flex">
        <div className="w-full max-w-md rounded-md bg-white p-4">{info}</div>
      </div>
    </div>
  );
};

export default Banner;

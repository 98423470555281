/* eslint-disable react/no-danger */
import Head from 'next/head';
import { FC } from 'react';
import { Article, Organization, Person, WebSite } from 'schema-dts';

type Schema = Article | Organization | Person | WebSite;

interface LdJsonProps {
  schema: Exclude<Schema, string>;
}

export const LdJson: FC<LdJsonProps> = ({ schema }) => (
  <Head>
    <script
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://schema.org',
          ...schema,
        }),
      }}
      type="application/ld+json"
    />
  </Head>
);

import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';

import type { ImageSizes } from '../../helpers/images';
import { getFirstStoryblokParagraph } from '../../utils/renderStoryblokRichText';
import { normaliseString } from '../../utils/strings';
import BannerShell from './BannerShell';
import type {
  ArtistCollaborationStatus,
  BannerAnalytics,
  BannerLayout,
  StoryblokBannerV3,
} from './types';

type Props = {
  banner: StoryblokBannerV3<ArtistPageType>;
  layout: BannerLayout;
  className?: string;
  imageSizes: ImageSizes;
  analytics: BannerAnalytics;
  artistCollaborationStatuses: ArtistCollaborationStatus[];
};

const BannerArtist: React.FC<Props> = ({
  analytics,
  artistCollaborationStatuses = [],
  banner,
  className,
  imageSizes,
  layout,
}) => {
  return (
    <BannerShell
      analytics={useBannerAnalytics(
        banner,
        analytics,
        artistCollaborationStatuses,
      )}
      category={useBannerCategory(banner)}
      className={className}
      description={useBannerDescription(banner)}
      headline={useBannerHeadline(banner)}
      href={useBannerHref(banner)}
      images={useBannerImages(banner, layout)}
      imageSizes={imageSizes}
      labels={useBannerLabels(banner, artistCollaborationStatuses)}
      layout={layout}
      video={useBannerVideo(banner)}
    />
  );
};

export default BannerArtist;

export const useBannerHref = (
  banner: StoryblokBannerV3<ArtistPageType>,
): string => {
  return `/${banner.link.story.full_slug}`;
};

export const useBannerVideo = (banner: StoryblokBannerV3<ArtistPageType>) => {
  return banner.overrideVideo?.vimeo_oembed ? banner.overrideVideo : undefined;
};

export const useBannerImages = (
  banner: StoryblokBannerV3<ArtistPageType>,
  layout: BannerLayout,
): StoryblokImage[] => {
  return useMemo(() => {
    if (banner.overrideImages && banner.overrideImages.length > 0) {
      return banner.overrideImages.slice(0, 3);
    }

    const page = banner.link.story;
    if (page?.content === undefined) return [];

    const images = page.content.studioGallery
      .filter((p): p is GalleryImage => p.component === 'artistGalleryImage')
      .map((p) => p.image);

    return layout === 'HERO'
      ? images.slice(0, 3)
      : page.content.thumbnail !== undefined
      ? [page.content.thumbnail]
      : images.slice(0, 1);
  }, [banner]);
};

export const useBannerCategory = (
  banner: StoryblokBannerV3<ArtistPageType>,
): string => {
  const { t } = useTranslation('banner');
  return banner.overrideCategory || t('v3.artist.title');
};

export const useBannerHeadline = (
  banner: StoryblokBannerV3<ArtistPageType>,
): string => {
  return banner.overrideHeadline || banner.link.story.content.name;
};

export const useBannerDescription = (
  banner: StoryblokBannerV3<ArtistPageType>,
): StoryblokTextAreaType => {
  return useMemo(() => {
    if (banner.overrideDescription) return banner.overrideDescription;

    return getFirstStoryblokParagraph(
      banner.link.story.content.practiceOverview,
    );
  }, [banner]);
};

export const useBannerLabels = (
  banner: StoryblokBannerV3<ArtistPageType>,
  artistCollaborationStatuses: ArtistCollaborationStatus[],
) => {
  const { t } = useTranslation('banner');

  return useMemo(() => {
    if (banner.overrideLabel) {
      return [{ active: false, loading: false, text: banner.overrideLabel }];
    }

    const artistName = normaliseString(banner.link.story.content.name);
    const collaborationStatus = artistCollaborationStatuses.find(
      (a) => a.name === artistName,
    );

    if (collaborationStatus === undefined) return [];

    const labels = [];

    if (collaborationStatus.numberOfCollaborations > 0) {
      labels.push({
        active: false,
        loading: false,
        text: t('v3.artist.labels.collaborationCount', {
          count: collaborationStatus.numberOfCollaborations,
        }),
      });
    }

    if (collaborationStatus.hasUpcomingCollaboration) {
      labels.push({
        active: false,
        loading: false,
        text: t('v3.artist.labels.upcomingRelease'),
      });
    }

    return labels;
  }, [banner]);
};

export const useBannerAnalytics = (
  banner: StoryblokBannerV3<ArtistPageType>,
  base: BannerAnalytics,
  artistCollaborationStatuses: ArtistCollaborationStatus[],
) => {
  const artistName = normaliseString(banner.link.story.content.name);
  const collaborationStatus = artistCollaborationStatuses.find(
    (a) => a.name === artistName,
  );

  return {
    page: base.page,
    type: base.type,
    position: base.position,
    artist: {
      id: banner.link.story.content.internalId,
      name: artistName,
      hasUpcomingRelease:
        collaborationStatus?.hasUpcomingCollaboration || false,
      hasUpcomingProductPage:
        collaborationStatus?.hasUpcomingCollaboration || false,
      collaborations: collaborationStatus?.numberOfCollaborations || 0,
    },
  };
};

const hostname = process.env.NEXT_PUBLIC_HOSTNAME;

export const validString = (key: string, value = '') => {
  if (value.trim()) {
    return { [key]: value.trim() };
  }
  return {};
};

export const validSlug = (key: string, value = '') => {
  if (hostname && value.trim()) {
    return validString(key, hostname + value);
  }
  return {};
};

import { BannerCard } from '@design-system';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { useHubspotChat } from '../../hooks/useHubspotChat';
import { TrackLinkCardSalesCtaClicked } from '../../utils/analytics';

const LinkCardCta = ({
  buttonLabel,
  className,
  href,
  title,
}: {
  className?: string;
  title: string;
  href?: string;
  buttonLabel?: string;
}) => {
  const { openHandler } = useHubspotChat();
  const { t } = useTranslation('linkCards');
  const preparedButtonLabel = buttonLabel || t('cta.ctaTextDefault');

  if (href) {
    return (
      <BannerCard
        as="a"
        aspectRatioClassName={classNames(className)}
        hasLargeTitle
        href={href}
        onClick={() => {
          TrackLinkCardSalesCtaClicked({ cta: title });
        }}
        title={title}
        variant="secondary"
      />
    );
  }

  return (
    <BannerCard
      as="button"
      aspectRatioClassName={classNames(className)}
      buttonLabel={preparedButtonLabel}
      hasLargeTitle
      onClick={() => {
        openHandler();
        TrackLinkCardSalesCtaClicked({ cta: title });
      }}
      title={title}
      variant="secondary"
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default LinkCardCta;

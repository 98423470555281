import { BannerCard } from '@design-system';
import classNames from 'classnames';
import React from 'react';

import { ImageSizes } from '../../helpers/images';
import { LinkCardPlacement } from '../../types/Analytics';
import { AssetStoryblok } from '../../types/generated-storyblok';
import { TrackLinkCardClicked } from '../../utils/analytics';

export interface LinkCardMediaProps {
  analyticsPlacement?: LinkCardPlacement;
  title: string;
  href: string;
  image: AssetStoryblok;
  imageSize: ImageSizes;
  className?: string;
}

const LinkCardMedia: React.FC<LinkCardMediaProps> = ({
  analyticsPlacement,
  className,
  href,
  image,
  imageSize,
  title,
}) => {
  return (
    <BannerCard
      as="a"
      aspectRatioClassName={classNames(className)}
      hasLargeTitle
      href={href}
      image={image}
      imageSize={imageSize}
      onClick={() => {
        TrackLinkCardClicked({
          placement: analyticsPlacement,
          url: href,
          title,
        });
      }}
      title={title}
      variant="image"
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default LinkCardMedia;

import { Accordion, AccordionItem } from '@design-system';
import { FC, useMemo } from 'react';

import { TrackAccordion } from '../../../utils/analytics';
import { renderStoryblokRichText } from '../../../utils/renderStoryblokRichText';
import { Image } from '../../Image';

interface Props {
  analytics: Analytics;
  faq?: SupportPageType;
  image?: StoryblokImage;
  title: string;
}

export const SupportFAQ: FC<Props> = ({ analytics, faq, image, title }) => {
  const questions = useMemo(() => {
    return (faq?.content || []).filter((blok) => {
      if (blok.component === 'supportBlock') {
        return (blok as SupportBlock).isAccordion;
      }
      return false;
    }) as SupportBlock[];
  }, [faq?.content]);

  if (questions.length === 0) {
    return null;
  }

  return (
    <section>
      <div className="mb-4">
        <h2 className="text-md text-neutral-4 md:text-lg">{title}</h2>
      </div>
      <div className="grid-section">
        <div className="order-2 col-span-12 md:order-1 md:col-span-6">
          <Accordion>
            {questions.map((question) => (
              <AccordionItem
                key={question._uid}
                analytics={() =>
                  TrackAccordion({
                    ...analytics,
                    title: question.title,
                    isFAQ: true,
                    expanded: true,
                  })
                }
                subject={renderStoryblokRichText(question.title)}
              >
                {renderStoryblokRichText(question.text, {
                  paragraphClassName: 'pb-0',
                })}
              </AccordionItem>
            ))}
          </Accordion>
        </div>

        {image && (
          <div className="order-1 col-span-12 mb-4 md:order-2 md:col-span-6 md:mb-0">
            <div className="overflow-hidden rounded-sm">
              <Image
                alt={image.alt}
                focus={image.focus}
                size={{
                  sm: { ratio: '3/2', span: 12 },
                  md: { ratio: '3/2', span: 6 },
                  lg: { ratio: '3/2', span: 6 },
                }}
                src={image.filename}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

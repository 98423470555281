import { useTranslation } from 'next-i18next';
import React from 'react';
import type { Organization } from 'schema-dts';

import { LdJson } from '../../scripts/LdJson';
import { validSlug } from '../../utils/schema';

export const OrganizationSchema = ({
  hostname = process.env.NEXT_PUBLIC_HOSTNAME,
}) => {
  const { t } = useTranslation('homev2');

  const organization: Organization = {
    '@type': 'Organization',
    ...validSlug('logo', 'android-chrome-384x384.png'),
    'url': hostname,
    'address': {
      '@type': 'PostalAddress',
      'addressCountry': 'NL',
      'addressLocality': 'Amsterdam',
      'postalCode': '1105 AA',
      'streetAddress': 'Avant Arte B.V. Abcouderstraatweg 130-B',
    },
    'name': 'Avant Arte',
    'sameAs': [
      'https://www.instagram.com/avant.arte/',
      'https://www.instagram.com/avantarteinsiders/',
      'https://twitter.com/avant_arte/',
      'https://discord.com/invite/8fReZrWtAP/',
      'https://www.youtube.com/c/AvantArte/featured/',
    ],
    'brand': {
      '@type': 'Brand',
      'name': 'Avant Arte',
      'slogan': t('header.title'),
    },
  };

  return <LdJson schema={organization} />;
};

import Link from 'next/link';
import React, { ReactNode } from 'react';

import { TrackLinkClicked } from '../../../utils/analytics';
import ArtistThumbnail from '../ArtistThumbnail';

type Props = {
  artist?: {
    name: string;
    slug: string;
    thumbnail: {
      filename: string;
      focus?: string;
    };
  };
  overline?: string | ReactNode;
  target?: '_blank' | '_self';
  thumbnailShape?: 'square' | 'circle';
  thumbnailSize?: 'xs' | 'sm' | 'md';
  page: string;
};

const ArtistTile: React.FC<Props> = ({
  artist,
  overline,
  page,
  target = '_self',
  thumbnailShape = 'square',
  thumbnailSize = 'sm',
}) => {
  return (
    <Link
      className="group"
      data-testid={`artist-tile-${artist?.name.toLowerCase()}`}
      href={`/${artist?.slug}`}
      onClick={() =>
        TrackLinkClicked({
          page: page || '',
          context: 'artist tile',
          title: artist?.name || '',
          url: artist?.slug || '',
          isExternal: false,
        })
      }
      prefetch={false}
      target={target}
    >
      <div className="align-items flex items-center space-x-3">
        <div className="shrink-0">
          <ArtistThumbnail
            name={artist?.name || page}
            size={thumbnailSize}
            thumbnail={artist?.thumbnail}
            thumbnailShape={thumbnailShape}
          />
        </div>
        <div className="flex-1 overflow-hidden">
          <div className="truncate transition duration-300 group-hover:text-neutral-4">
            {artist?.name}
          </div>
          {overline && (
            <div className="truncate text-neutral-4">{overline}</div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default ArtistTile;

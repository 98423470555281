import Link from 'next/link';

import { ImageSizes } from '../../helpers/images';
import type { BannerV3ClickedOptions } from '../../types/Analytics';
import type { VimeoPlugin } from '../../types/Vimeo';
import { TrackBannerV3Clicked } from '../../utils/analytics';
import type { BannerLabel, BannerLayout } from './types';
import BannerHero from './visual/BannerHero';
import BannerTile from './visual/BannerTile';

type Props = {
  className?: string;
  layout: BannerLayout;
  href: string;
  category: string;
  headline: string;
  description: string;
  images: StoryblokImage[];
  video?: VimeoPlugin;
  videoCover?: StoryblokImage;
  labels: BannerLabel[];
  imageSizes: ImageSizes;
  isExternal?: boolean;
  analytics: Pick<
    BannerV3ClickedOptions,
    'page' | 'type' | 'position' | 'journal'
  >;
};

const BannerShell: React.FC<Props> = ({
  analytics,
  category,
  className,
  description,
  headline,
  href,
  imageSizes,
  images,
  isExternal = false,
  labels,
  layout,
  video,
  videoCover,
}) => {
  const hasImagery = video !== undefined || images.length > 0;
  const hasText = !!category && !!headline;

  if (!hasImagery || !hasText) return null;

  return (
    <Link
      className={className}
      href={href}
      onClick={() => {
        TrackBannerV3Clicked({
          ...analytics,
          title: headline,
          url: href,
        });
      }}
      prefetch={false}
      target={isExternal ? '_blank' : '_self'}
    >
      {layout === 'HERO' ? (
        <BannerHero
          category={category}
          description={description}
          headline={headline}
          images={images}
          imageSizes={imageSizes}
          labels={labels}
          video={video}
          videoCover={videoCover}
        />
      ) : (
        <BannerTile
          category={category}
          description={description}
          headline={headline}
          image={images[0]}
          imageSizes={imageSizes}
          labels={labels}
          layout={layout}
        />
      )}
    </Link>
  );
};

export default BannerShell;

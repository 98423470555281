import { Pill } from '@design-system';
import { render } from 'storyblok-rich-text-react-renderer-ts';

import { BannerLabel } from '../types';

type Props = {
  labels: BannerLabel[];
  category: string;
  headline: string;
  description: string;
};

const BannerInfo: React.FC<Props> = ({
  category,
  description,
  headline,
  labels,
}) => {
  return (
    <>
      <div className="flex flex-wrap gap-2 pb-3">
        {labels.map((label) => (
          <Pill
            key={label.text}
            isActive={label.active}
            isLoading={label.loading}
            label={label.text}
          />
        ))}
      </div>

      <div className="w-full">
        <div className="pb-2">
          <span className="inline text-md transition-colors duration-300 ease-out group-hover:text-neutral-4 group-focus:text-neutral-4 md:text-lg">
            {category}&nbsp;
          </span>
          <h3 className="inline text-md font-bold transition-colors duration-300 ease-out group-hover:text-neutral-4 group-focus:text-neutral-4 md:text-lg">
            {headline}
          </h3>
        </div>

        <div className="line-clamp-3 transition-colors duration-300 ease-out group-hover:text-neutral-4 group-focus:text-neutral-4">
          {render(description, {
            markResolvers: {
              link: (childredn) => <>{childredn}</>,
            },
          })}
        </div>
      </div>
    </>
  );
};

export default BannerInfo;

import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';

import type { ImageSizes } from '../../helpers/images';
import { ReleasePage } from '../../types/ReleaseContentType';
import { capitalize } from '../../utils/strings';
import BannerShell from './BannerShell';
import type { BannerAnalytics, BannerLayout, StoryblokBannerV3 } from './types';

type Banner = StoryblokBannerV3<ReleasePage['content']>;

type Props = {
  analytics: BannerAnalytics;
  banner: Banner;
  className?: string;
  layout: BannerLayout;
  imageSizes: ImageSizes;
};

export const BannerRelease: React.FC<Props> = ({
  analytics,
  banner,
  className,
  imageSizes,
  layout,
}) => {
  const { t } = useTranslation('banner');

  const {
    overrideCategory,
    overrideDescription,
    overrideHeadline,
    overrideImages,
    overrideLabel,
    overrideVideo,
  } = banner;

  const { backgroundImage, description, releaseStatus, title } =
    banner.link.story.content;

  const images = useMemo(() => {
    if (overrideImages?.length) {
      return overrideImages.slice(0, 3);
    }
    return backgroundImage ? [backgroundImage] : [];
  }, [overrideImages, backgroundImage]);

  const labels = useMemo(() => {
    const isActive = releaseStatus === 'live';
    const text =
      overrideLabel || capitalize(releaseStatus) || t('v3.release.label');
    return [{ active: isActive, loading: false, text }];
  }, [releaseStatus, overrideLabel]);

  return (
    <BannerShell
      analytics={{ ...analytics, journal: { title } }}
      category={overrideCategory || t('v3.release.category')}
      className={className}
      description={overrideDescription || description}
      headline={overrideHeadline || title}
      href={`/${banner.link.story.full_slug}`}
      images={images}
      imageSizes={imageSizes}
      labels={labels}
      layout={layout}
      video={overrideVideo?.vimeo_oembed && overrideVideo}
    />
  );
};

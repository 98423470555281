import classNames from 'classnames';

import { PLACE_HOLDER_IMAGE } from '../../../constants';
import { Image } from '../../Image';

type Props = {
  name: string;
  size?: 'xs' | 'sm' | 'md';
  thumbnail?: {
    filename: string;
    focus?: string;
  };
  thumbnailShape?: 'circle' | 'square';
};

const ArtistThumbnail: React.FC<Props> = ({
  name,
  size = 'sm',
  thumbnail,
  thumbnailShape = 'square',
}) => (
  <div
    className={classNames('overflow-hidden', {
      'h-9 w-9': size === 'xs',
      'h-12 w-12': size === 'sm',
      'h-14 w-14': size === 'md',
      'rounded-sm': thumbnailShape === 'square',
      'rounded-full': thumbnailShape === 'circle',
    })}
  >
    <Image
      alt={name}
      focus={thumbnail?.focus}
      hoverEffect
      src={thumbnail?.filename || PLACE_HOLDER_IMAGE}
      width={size === 'sm' ? 48 : 64}
    />
  </div>
);

export default ArtistThumbnail;

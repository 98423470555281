import { isPast, isValid } from 'date-fns';

import { DrawStage } from '../types/Draw';
import { useTimeout } from './useTimeout';

// After moving all api calls to generated queries,
// this helper functions needs to go away
export const useDrawStage = (draw?: {
  closeAt: string;
  preOrderCloseAt?: string | null;
}): DrawStage => {
  useTimeout(draw?.closeAt);
  useTimeout(draw?.preOrderCloseAt);

  if (!draw) {
    return 'UNKNOWN';
  }

  if (!draw.preOrderCloseAt || !isValid(new Date(draw.preOrderCloseAt))) {
    return isPast(new Date(draw.closeAt)) ? 'SOLD_OUT' : 'DRAW';
  }

  const isClosed = isPast(new Date(draw.preOrderCloseAt));

  if (draw.preOrderCloseAt === draw.closeAt) {
    return isClosed ? 'FCFS_PRE_ORDER_CLOSED' : 'FCFS_PRE_ORDER_OPEN';
  }

  return isClosed ? 'DRAW_PRE_ORDER_CLOSED' : 'DRAW_PRE_ORDER_OPEN';
};

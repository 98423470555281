import { Badge, Icon } from '@design-system';

import { VimeoPlugin } from '../../types/Vimeo';
import { lpad } from '../../utils/strings';
import { Image } from '../Image';

type Props = {
  coverImage: StoryblokImage;
  video: VimeoPlugin;
  onClick?: () => void;
};

const VideoCard: React.FC<Props> = ({ coverImage, onClick, video }) => {
  return (
    <div
      className="group relative cursor-pointer overflow-hidden rounded-sm"
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <Image
        alt={coverImage.alt}
        focus={coverImage.focus}
        hoverEffect
        size={{
          sm: { ratio: '1/1', span: 12 },
          md: { ratio: '3/2', span: 6 },
          lg: { ratio: '3/2', span: 6 },
        }}
        src={coverImage.filename}
      />
      <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-neutral-6/40 transition duration-300 ease-out group-hover:bg-neutral-6/0">
        {video?.vimeo_oembed?.response?.duration && (
          <div className="absolute right-4 top-4">
            <Badge
              label={formatSecondsDuration(
                video.vimeo_oembed?.response?.duration,
              )}
            />
          </div>
        )}

        <div className="rounded-full bg-white text-neutral-6">
          <Icon className="m-3" name="ic_play" size="md" />
        </div>
      </div>
    </div>
  );
};

export const formatSecondsDuration = (total: number) => {
  const minutes = lpad(Math.floor(total / 60).toString(), '0', 2);
  const seconds = lpad((total % 60).toString(), '0', 2);

  return `${minutes}:${seconds}`;
};

export default VideoCard;

import { isBrowser } from '@utils/isBrowser';
import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';

import type { ImageSizes } from '../../helpers/images';
import { useCountdown } from '../../hooks/useCountdown';
import { useIsPreOrder } from '../../hooks/useIsPreOrder';
import { useShopifyProducts } from '../../hooks/useShopifyProducts';
import { GeneratedGetDrawByProductIDsQuery } from '../../types/generated';
import { ProductV2Storyblok } from '../../types/generated-storyblok';
import { VimeoPlugin } from '../../types/Vimeo';
import { formatDate } from '../../utils/datetime';
import { isInNext24Hours } from '../../utils/product';
import { getFirstStoryblokParagraph } from '../../utils/renderStoryblokRichText';
import { getBannerStatus } from '../ProductTile/utils/status';
import BannerShell from './BannerShell';
import type { BannerAnalytics, BannerLayout, StoryblokBannerV3 } from './types';

type Props = {
  banner: StoryblokBannerV3<ProductType>;
  layout: BannerLayout;
  className?: string;
  imageSizes: ImageSizes;
  analytics: BannerAnalytics;
  productArtistNames: { [key: string]: string | null };
  draws: GeneratedGetDrawByProductIDsQuery['getDrawByProductIDs'];
};

const BannerProduct: React.FC<Props> = ({
  analytics,
  banner,
  className,
  draws,
  imageSizes,
  layout,
  productArtistNames = {},
}) => {
  const { cover, video } = useBannerVideo(banner);
  return (
    <BannerShell
      analytics={useBannerAnalytics(banner, analytics, productArtistNames)}
      category={useBannerCategory(banner)}
      className={className}
      description={useBannerDescription(banner)}
      headline={useBannerHeadline(banner, productArtistNames)}
      href={useBannerHref(banner)}
      images={useBannerImages(banner, layout)}
      imageSizes={imageSizes}
      labels={useBannerLabels(banner, draws)}
      layout={layout}
      video={video}
      videoCover={cover}
    />
  );
};

export default BannerProduct;

export const useBannerHref = (
  banner: StoryblokBannerV3<ProductType>,
): string => {
  return `/${banner.link.story.full_slug}`;
};

export const useBannerVideo = (banner: StoryblokBannerV3<ProductType>) => {
  return useMemo(() => {
    if (banner.overrideVideo && banner.overrideVideo.vimeo_oembed !== null) {
      return { cover: banner.overrideImages?.[0], video: banner.overrideVideo };
    }

    if (banner.overrideImages && banner.overrideImages.length > 0) {
      return { cover: undefined, video: undefined };
    }

    const video = banner.link.story.content.images.find(
      (p): p is ProductVideoComponent => p.component === 'Video',
    );

    return {
      cover: video?.coverImage,
      video: video?.vimeo as unknown as VimeoPlugin,
    };
  }, [banner]);
};

export const useBannerImages = (
  banner: StoryblokBannerV3<ProductType>,
  layout: BannerLayout,
): StoryblokImage[] => {
  return useMemo(() => {
    if (banner.overrideImages && banner.overrideImages.length > 0) {
      return banner.overrideImages.slice(0, 3);
    }

    const page = banner.link.story;
    const images =
      page.content.images
        .filter((p): p is ProductImageComponent => p.component === 'image')
        .map((p) => p.image) || [];

    return layout === 'HERO'
      ? images.slice(0, 3)
      : page.content.highlightImage && page.content.highlightImage.filename
      ? [page.content.highlightImage]
      : images.slice(0, 1);
  }, [banner]);
};

export const useBannerCategory = (
  banner: StoryblokBannerV3<ProductType>,
): string => {
  const { t } = useTranslation('common');

  if (banner.overrideCategory) return banner.overrideCategory;

  const page = banner.link.story;

  if (page.content.medium?.includes('collectible')) {
    return t('mediums.collectible');
  }

  if (page.content.medium?.includes('print')) {
    return t('mediums.print');
  }

  if (page.content.medium?.includes('sculpture')) {
    return t('mediums.sculpture');
  }

  if (page.content.medium?.includes('studioworks')) {
    return t('mediums.studioworks');
  }

  return t('mediums.fallback');
};

export const useBannerHeadline = (
  banner: StoryblokBannerV3<ProductType>,
  productArtistNames: { [key: string]: string | null },
): string => {
  if (banner.link.story.content.component === 'product_v2') {
    banner.link.story.content.seo = {
      ...banner.link.story.content.seo,
      title: (banner.link.story.content as unknown as ProductV2Storyblok)
        .seoTitle,
    };
  }

  return (
    banner.overrideHeadline ||
    productArtistNames[banner.link.story.content.title] ||
    banner.link.story.content.seo.title
  );
};

export const useBannerDescription = (
  banner: StoryblokBannerV3<ProductType>,
): StoryblokTextAreaType => {
  if (
    banner.overrideDescription &&
    banner.overrideDescription.content &&
    banner.overrideDescription.content.length > 0 &&
    banner.overrideDescription.content[0].content &&
    banner.overrideDescription.content[0].content.length > 0
  )
    return banner.overrideDescription;

  return getFirstStoryblokParagraph(
    banner.link.story.content.details[0]?.description,
  );
};

const getCountDownDate = (product: Product) => {
  if (
    product?.releaseType === 'timed' &&
    product.closingDate &&
    product.availability === 'Available'
  ) {
    return product?.closingDate;
  }
  if (product?.availability === 'Coming soon') {
    return product?.date;
  }
  return;
};

export const useBannerLabels = (
  banner: StoryblokBannerV3<ProductType>,
  draws: GeneratedGetDrawByProductIDsQuery['getDrawByProductIDs'],
) => {
  const { t } = useTranslation(['product', 'banner']);

  if (banner.overrideLabel) {
    return [{ active: false, loading: false, text: banner.overrideLabel }];
  }

  const page = banner.link.story;
  if (page?.content === undefined) return [];

  if (page.content.component === 'product_v2') {
    page.content.id = [
      {
        id: (page.content as unknown as ProductV2Storyblok).shopifyId,
      },
    ];
  }

  const productId = page.content.id[0]?.id;
  const { loading, products } = useShopifyProducts({
    identifiers: productId ? [productId] : [],
  });
  const isPreOrder = useIsPreOrder(
    draws?.find((draw) => draw.productId === productId),
  );

  const product: Product | undefined = products[0];

  const countDownDate = product && getCountDownDate(product);
  const { destructuredDate } = useCountdown({
    addDaysToHours: !!(
      countDownDate && isInNext24Hours(new Date(countDownDate))
    ),
    date: countDownDate ? new Date(countDownDate) : undefined,
  });

  if (!isBrowser()) {
    return [];
  }

  if (loading) {
    return [{ active: false, loading: true, text: '' }];
  }

  const tileStatus = product ? getBannerStatus(product, isPreOrder) : null;
  if (tileStatus === null) {
    return [];
  }

  const formattedDateLong = formatDate(product.date, {
    includeYear: true,
    includeWeekday: false,
  });

  return [
    {
      active: tileStatus.active,
      loading: false,
      text: t(tileStatus.key, {
        closingCountdown: t('product:countdown.time', destructuredDate),
        date: `${formattedDateLong}`,
        countDownDays: t('product:countdown.days', destructuredDate),
      }),
    },
  ];
};

export const useBannerAnalytics = (
  banner: StoryblokBannerV3<ProductType>,
  base: BannerAnalytics,
  productArtistNames: { [key: string]: string | null },
) => {
  if (banner.link.story.content.component === 'product_v2') {
    banner.link.story.content.id = [
      {
        id: (banner.link.story.content as unknown as ProductV2Storyblok)
          .shopifyId,
      },
    ];
  }

  return {
    page: base.page,
    type: base.type,
    position: base.position,
    product: {
      id: banner.link.story.content.id[0]?.id,
      title: banner.link.story.content.title,
      type: 'Product',
      artistName: productArtistNames[banner.link.story.content.title],
      releaseType: banner.link.story.content.releaseType,
      editionSize: banner.link.story.content.editionSize,
      releaseDate: banner.link.story.content.launchDate,
      medium: banner.link.story.content.medium,
    },
  };
};

import { BannerCard } from '@design-system';
import classNames from 'classnames';
import React from 'react';

import { LinkCardPlacement } from '../../types/Analytics';
import { TrackLinkCardClicked } from '../../utils/analytics';

const LinkCardText = ({
  analyticsPlacement,
  className,
  href,
  title,
}: {
  className?: string;
  analyticsPlacement?: LinkCardPlacement;
  title: string;
  href: string;
}) => {
  return (
    <BannerCard
      as="a"
      aspectRatioClassName={classNames(className)}
      hasLargeTitle
      href={href}
      onClick={() => {
        TrackLinkCardClicked({
          placement: analyticsPlacement,
          url: href,
          title,
        });
      }}
      title={title}
      variant="primary"
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default LinkCardText;

import { isBrowser } from '@utils/isBrowser';
import { isPast, isValid } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

export const useTimeout = (date?: string | null) => {
  const endtime = useMemo(() => new Date(date || ''), [date]);

  const [isOver, setIsOver] = useState(isPast(endtime));

  useEffect(() => {
    if (!isBrowser() || !isValid(endtime)) {
      return () => null;
    }

    const interval = window.setInterval(() => {
      if (isPast(endtime)) {
        setIsOver(true);
        window.clearInterval(interval);
      } else {
        setIsOver(false);
      }
    }, 1000);

    return () => {
      window.clearInterval(interval);
    };
  }, [endtime]);

  return isOver;
};

import { AuthEntryPoint } from '../../../features/auth/types';
import type { ButtonLocation } from '../../../types/Subscriptions';
import { GeneralSubscription } from '../../GeneralSubscription';
import { Image } from '../../Image';

type Props = {
  title: string;
  description: string;
  backgroundImage?: StoryblokImage;
  buttonLocation: ButtonLocation;
  source: AuthEntryPoint;
};

const SignupBlock: React.FC<Props> = ({
  backgroundImage,
  buttonLocation,
  description,
  source,
  title,
}) => (
  <div className="relative w-full overflow-hidden rounded-md border border-divider/20 bg-white">
    {backgroundImage?.filename && (
      <div className="absolute inset-0">
        <Image
          alt={backgroundImage.alt}
          className="h-full"
          focus={backgroundImage.focus}
          size={{ sm: { span: 12, ratio: '9/4' } }}
          src={backgroundImage.filename}
        />
      </div>
    )}
    <div className="relative flex h-full w-full flex-col items-center justify-center p-6 md:py-32">
      <div className="flex flex-col items-center justify-center md:w-1/2 lg:w-1/3">
        <h2 className="mb-3 text-center text-md font-bold md:text-lg">
          {title}
        </h2>
        <span className="block text-center">{description}</span>
        <div className="mt-6" />
        <GeneralSubscription
          analyticsButtonLocation={buttonLocation}
          inlineInputs
          source={source}
        />
      </div>
    </div>
  </div>
);

export default SignupBlock;

import classNames from 'classnames';
import React from 'react';

import { ImageSizes } from '../../helpers/images';
import { LinkCardPlacement } from '../../types/Analytics';
import LinkCardCta from './LinkCardCta';
import LinkCardMedia from './LinkCardMedia';
import LinkCardText from './LinkCardText';

const IMAGE_SIZES_FIRST: ImageSizes = {
  sm: { ratio: '4/3', span: 12 },
  md: { ratio: '3/2', span: 12 },
  lg: { ratio: '3/2', span: 6 },
};

const IMAGE_SIZES_OTHER: ImageSizes = {
  sm: { ratio: '4/3', span: 12 },
  md: { ratio: '3/4', span: 6 },
  lg: { ratio: '3/4', span: 3 },
};

const LinkCardGroup = ({
  analyticsPlacement,
  cards,
}: {
  cards?: LinkCardComponent[];
  analyticsPlacement: LinkCardPlacement;
}) => {
  const classMapper = (index: number) => {
    return index === 0
      ? 'aspect-[4/3] md:col-span-2 md:aspect-[3/2]'
      : 'aspect-[4/3] md:aspect-[3/4] lg:aspect-auto';
  };

  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
      {cards?.map((card, index) => {
        if (card.component === 'linkCardCTA') {
          return (
            <LinkCardCta
              key={card._uid}
              buttonLabel={card.ctaText}
              className={classNames(classMapper(index))}
              href={card.ctaLink?.url}
              title={card.headline}
            />
          );
        }

        if (card.component === 'linkCardText') {
          return (
            <LinkCardText
              key={card._uid}
              analyticsPlacement={analyticsPlacement}
              className={classNames(classMapper(index))}
              href={
                card.link.story?.full_slug
                  ? `/${card.link.story.full_slug}`
                  : card.link.url
              }
              title={card.headline}
            />
          );
        }

        if (card.component === 'linkCardMedia') {
          return (
            <LinkCardMedia
              key={card._uid}
              analyticsPlacement={analyticsPlacement}
              className={classNames(classMapper(index))}
              href={
                card.link.story?.full_slug
                  ? `/${card.link.story.full_slug}`
                  : card.link.url
              }
              image={card.image}
              imageSize={index === 0 ? IMAGE_SIZES_FIRST : IMAGE_SIZES_OTHER}
              title={card.headline}
            />
          );
        }

        return null;
      })}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default LinkCardGroup;
